import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchOccupyTmplList, addOccupyTmpl, updateOccupyTmpl } from "@/api/charge";
import store from "@/store";
import Pagination from "@/components/Pagination";
import { deleteOccupyTmpl } from "../../../api/charge";
export default {
  name: "wareList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      // upload
      textMap: {
        update: "编辑模板",
        create: "添加模板"
      },
      dialogTitle: 'create',
      rules: {
        name: [{
          required: true,
          message: "名称必填",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "收费说明必填",
          trigger: "blur"
        }],
        start_time: [{
          required: true,
          message: "开始时间必填",
          trigger: "blur"
        }],
        end_time: [{
          required: true,
          message: "结束时间必填",
          trigger: "blur"
        }],
        amount: [{
          required: true,
          message: "收费价格必填",
          trigger: "blur"
        }],
        max_amount: [{
          required: true,
          message: "封顶金额必填",
          trigger: "blur"
        }]
      },
      timeTypeOptions: [{
        value: 'minute',
        label: '分钟'
      }, {
        value: 'hour',
        label: '小时'
      }, {
        value: 'day',
        label: '天'
      }],
      villageDialog: false,
      dialogForm: {
        name: "",
        content: "",
        time_type: "minute",
        start_time: 1,
        end_time: 2,
        interval: 1,
        amount: 0,
        max_amount: 0
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      fetchOccupyTmplList(this.searchForm).then(function (response) {
        console.log(response);
        _this.tableLoading = false;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log("页数");
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleAdd: function handleAdd() {
      var _this2 = this;

      this.villageDialog = true;
      this.dialogTitle = "create";
      this.$nextTick(function () {
        _this2.$refs["dialogForm"].clearValidate();
      });
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;

      var data = Object.assign({}, this.dialogForm);
      addOccupyTmpl(data).then(function (res) {
        _this3.$message({
          type: "success",
          message: "添加模板成功"
        });

        _this3.villageDialog = false;

        _this3.getTableData();
      }).catch(function (err) {
        _this3.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleDel: function handleDel(row) {
      var _this4 = this;

      console.log("删除模板");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664 ".concat(row.name, "?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        deleteOccupyTmpl(row.id).then(function (res) {
          _this4.$message({
            type: "success",
            message: "删除成功"
          });

          var index = _this4.dataList.indexOf(row);

          _this4.dataList.splice(index, 1);
        }).catch(function (err) {
          _this4.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleEdit: function handleEdit(row) {
      var data = Object.assign({}, row);
      this.dialogTitle = "update";
      this.villageDialog = true;
      this.dialogForm = data;
    },
    handleEditBtn: function handleEditBtn() {
      var _this5 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this5.dialogForm);
          updateOccupyTmpl(data.id, data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this5.$message({
              type: "success",
              message: "编辑成功"
            });

            _this5.villageDialog = false;

            _this5.getTableData();
          }).catch(function (err) {
            _this5.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    getTimeType: function getTimeType(type) {
      switch (type) {
        case 'minute':
          return "分钟";

        case 'hour':
          return "小时";

        case 'day':
          return "天";

        default:
          break;
      }
    }
  }
};